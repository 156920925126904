import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.hidePastEvents();
  }

  hidePastEvents() {
    let MINUTES_IN_MILLISECONDS = 30 * 60 * 1000;
    let now = new Date();
    let eventDateTime = new Date(Date.parse(this.element.dataset.datetime));
    eventDateTime = new Date(eventDateTime.getTime() + MINUTES_IN_MILLISECONDS);
    if (eventDateTime < now) this.element.remove();
    this.dispatchCompletedEvent();
  }

  dispatchCompletedEvent() {
    const event = new CustomEvent('eventCardProcessed', { bubbles: true });
    this.element.dispatchEvent(event);
  }
}
